export const hadiths = [
    "Sesungguhnya setiap amal itu tergantung niatnya, dan sesungguhnya setiap orang akan mendapatkan apa yang dia niatkan. (HR. Bukhari dan Muslim)",
    "Barang siapa yang menempuh jalan untuk mencari ilmu, maka Allah akan memudahkan baginya jalan ke surga. (HR. Muslim)",
    "Sesungguhnya Allah tidak melihat kepada rupa dan harta kalian, tetapi Dia melihat kepada hati dan amal kalian. (HR. Muslim)",
    "Sebaik-baik kalian adalah yang paling baik akhlaknya. (HR. Bukhari)",
    "Barangsiapa yang beriman kepada Allah dan hari akhir, hendaklah ia berkata yang baik atau diam. (HR. Bukhari dan Muslim)",
    "Tidak sempurna iman salah seorang dari kalian hingga ia mencintai saudaranya sebagaimana ia mencintai dirinya sendiri. (HR. Bukhari dan Muslim)",
    "Allah tidak akan mengasihi orang yang tidak mengasihi manusia. (HR. Bukhari dan Muslim)",
    "Muslim yang satu dengan yang lainnya adalah saudara. (HR. Bukhari dan Muslim)",
    "Barangsiapa yang tidak mensyukuri nikmat yang sedikit, maka ia tidak akan mampu mensyukuri nikmat yang banyak. (HR. Ahmad)",
    "Sesungguhnya kejujuran itu membawa kepada kebaikan, dan kebaikan itu membawa ke surga. (HR. Bukhari dan Muslim)",
    "Sedekah itu tidak akan mengurangi harta. (HR. Muslim)",
    "Barang siapa yang menutupi aib seorang muslim, maka Allah akan menutupi aibnya di dunia dan di akhirat. (HR. Muslim)",
    "Senyum kepada saudaramu adalah sedekah. (HR. Tirmidzi)",
    "Barangsiapa yang memberi makan orang yang berpuasa, maka ia akan mendapatkan pahala seperti orang yang berpuasa tersebut. (HR. Tirmidzi)",
    "Jangan marah, maka bagimu surga. (HR. Thabrani)",
    "Tuntutlah ilmu dari buaian hingga liang lahat. (HR. Muslim)",
    "Orang kuat bukanlah yang bisa mengalahkan orang lain, tetapi orang kuat adalah yang bisa mengendalikan dirinya ketika marah. (HR. Bukhari dan Muslim)",
    "Tidak akan masuk surga orang yang dalam hatinya ada kesombongan sebesar biji sawi. (HR. Muslim)",
    "Sesungguhnya Allah itu indah dan menyukai keindahan. (HR. Muslim)",
    "Sesungguhnya Allah mencintai orang yang jika bekerja, ia bekerja dengan baik. (HR. Thabrani)",
    "Barangsiapa yang beriman kepada Allah dan hari akhir, maka hendaklah ia memuliakan tamunya. (HR. Bukhari dan Muslim)",
    "Orang yang menunjukkan kebaikan sama dengan orang yang melakukannya. (HR. Tirmidzi)",
    "Sesungguhnya dunia itu adalah perhiasan dan sebaik-baik perhiasan dunia adalah wanita yang salehah. (HR. Muslim)",
    "Barangsiapa yang memudahkan urusan saudaranya, maka Allah akan memudahkan urusannya di dunia dan akhirat. (HR. Muslim)",
    "Barangsiapa yang tidak memiliki kasih sayang, maka tidak akan dikasihani. (HR. Muslim)",
    "Sesungguhnya di surga terdapat pintu yang disebut Ar-Rayyan, yang hanya dimasuki oleh orang-orang yang berpuasa. (HR. Bukhari dan Muslim)",
    "Setiap kebaikan adalah sedekah. (HR. Bukhari)",
    "Barangsiapa yang melepaskan kesulitan seorang mukmin di dunia, maka Allah akan melepaskan kesulitannya di akhirat. (HR. Muslim)",
    "Tidak akan masuk surga orang yang memutuskan tali silaturahmi. (HR. Bukhari dan Muslim)",
    "Tidak ada sesuatu yang lebih berat dalam timbangan seorang mukmin pada hari kiamat selain akhlak yang baik. (HR. Tirmidzi)",
    "Sebaik-baik manusia adalah yang paling bermanfaat bagi manusia. (HR. Thabrani)",
    "Sesungguhnya Allah mencintai orang yang bertakwa, kaya, dan tersembunyi. (HR. Muslim)",
    "Barangsiapa yang membaca satu huruf dari Kitabullah (Al-Quran), maka baginya satu kebaikan, dan satu kebaikan dilipatgandakan menjadi sepuluh kali. (HR. Tirmidzi)",
    "Barangsiapa yang shalat Subuh berjamaah, maka dia berada dalam jaminan Allah. (HR. Muslim)",
    "Barangsiapa yang menjaga shalat Dhuha, dosanya akan diampuni meskipun sebanyak buih di lautan. (HR. Tirmidzi)",
    "Orang yang paling dicintai Allah adalah yang paling bermanfaat bagi orang lain. (HR. Thabrani)",
    "Barangsiapa yang menunaikan hajat saudaranya, maka Allah akan menunaikan hajatnya. (HR. Muslim)",
    "Sesungguhnya Allah itu Maha Lembut dan menyukai kelembutan. (HR. Muslim)",
    "Barangsiapa yang beriman kepada Allah dan hari akhir, maka hendaklah ia menyambung tali silaturahmi. (HR. Bukhari dan Muslim)",
    "Sungguh menakjubkan perkara seorang mukmin, semua urusannya adalah baik. (HR. Muslim)",
    "Barangsiapa yang tidak bersyukur kepada manusia, maka ia tidak bersyukur kepada Allah. (HR. Tirmidzi)",
    "Sesungguhnya seorang mukmin itu jika bersin, ia memuji Allah. (HR. Bukhari dan Muslim)",
    "Barangsiapa yang berpuasa Ramadhan dengan penuh keimanan dan pengharapan, maka diampuni dosa-dosanya yang telah lalu. (HR. Bukhari dan Muslim)",
    "Barangsiapa yang shalat malam di bulan Ramadhan dengan penuh keimanan dan pengharapan, maka diampuni dosa-dosanya yang telah lalu. (HR. Bukhari dan Muslim)",
    "Barangsiapa yang berwudhu dengan sempurna, maka dosa-dosanya akan keluar dari tubuhnya, bahkan dari bawah kukunya. (HR. Muslim)",
    "Barangsiapa yang berpuasa sehari di jalan Allah, maka Allah akan menjauhkan wajahnya dari neraka sejauh tujuh puluh tahun perjalanan. (HR. Bukhari dan Muslim)",
    "Sesungguhnya Allah tidak akan mengubah keadaan suatu kaum hingga mereka mengubah keadaan yang ada pada diri mereka sendiri. (HR. Muslim)",
    "Barangsiapa yang berbuat baik kepada kalian, maka balaslah dengan kebaikan. (HR. Ahmad)",
    "Sesungguhnya Allah itu Maha Pengampun dan menyukai pengampunan. (HR. Tirmidzi)",
    "Barangsiapa yang menjaga shalat lima waktu, maka Allah akan memberikan cahaya, bukti, dan keselamatan baginya pada hari kiamat. (HR. Ahmad)",
    "Sesungguhnya orang yang paling mulia di antara kalian di sisi Allah adalah yang paling bertakwa. (HR. Bukhari dan Muslim)",
    "Barangsiapa yang merendahkan diri karena Allah, maka Allah akan meninggikan derajatnya. (HR. Muslim)",
    "Barangsiapa yang memberikan pinjaman kepada saudaranya dengan penuh keridhaan, maka ia akan mendapatkan pahala seperti sedekah. (HR. Ahmad)",
    "Barangsiapa yang menanam kebaikan, maka ia akan memetik kebahagiaan. (HR. Bukhari dan Muslim)",
    "Sesungguhnya Allah mencintai orang yang selalu berusaha memperbaiki dirinya. (HR. Ahmad)",
    "Barangsiapa yang menjaga lisannya, maka Allah akan menutupi aibnya. (HR. Tirmidzi)"
  ];
  
  export const duas = [
    "Ya Allah, berikanlah kami kebaikan di dunia dan kebaikan di akhirat, dan lindungilah kami dari siksa neraka. (HR. Bukhari dan Muslim)",
    "Ya Allah, sesungguhnya aku memohon kepada-Mu petunjuk, ketakwaan, kesucian, dan kekayaan. (HR. Muslim)",
    "Ya Allah, perbaikilah agamaku yang menjadi penjaga urusanku, perbaikilah duniaku yang menjadi tempat kehidupanku, dan perbaikilah akhiratku yang menjadi tempat kembaliku. (HR. Muslim)",
    "Ya Allah, ampunilah dosa-dosaku, kesalahanku, dan kelalaianku dalam urusanku. (HR. Bukhari dan Muslim)",
    "Ya Allah, aku berlindung kepada-Mu dari ilmu yang tidak bermanfaat, hati yang tidak khusyuk, nafsu yang tidak pernah puas, dan doa yang tidak dikabulkan. (HR. Muslim)",
    "Ya Allah, jauhkanlah aku dari segala macam keburukan dan lindungilah aku dari segala mara bahaya. (HR. Muslim)",
    "Ya Allah, sesungguhnya aku memohon kepada-Mu kecintaan-Mu dan kecintaan orang-orang yang mencintai-Mu, serta amalan yang dapat mendekatkanku kepada kecintaan-Mu. (HR. Tirmidzi)",
    "Ya Allah, aku berlindung kepada-Mu dari kelemahan, kemalasan, ketakutan, kekikiran, dan siksa kubur. (HR. Bukhari dan Muslim)",
    "Ya Allah, cukupkanlah aku dengan rezeki yang halal dan jauhkanlah aku dari yang haram. (HR. Tirmidzi)",
    "Ya Allah, jadikanlah sebaik-baik umurku pada akhirnya, dan sebaik-baik amalku adalah penutupnya, dan sebaik-baik hariku adalah hari ketika aku bertemu dengan-Mu. (HR. Ahmad)",
    "Ya Allah, mudahkanlah urusanku, lapangkanlah dadaku, dan berikanlah kelancaran dalam segala hal. (HR. Tirmidzi)",
    "Ya Allah, sesungguhnya aku memohon kepada-Mu kesehatan, keselamatan, dan kebaikan dalam segala urusan. (HR. Muslim)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang bersyukur atas nikmat-Mu, yang bersabar atas cobaan-Mu, dan yang ridha dengan takdir-Mu. (HR. Ahmad)",
    "Ya Allah, berikanlah aku kekuatan untuk menghindari segala macam maksiat dan berikanlah aku kemampuan untuk selalu berbuat kebaikan. (HR. Muslim)",
    "Ya Allah, berikanlah aku keikhlasan dalam setiap amalku, dan jauhkanlah aku dari sifat riya dan ujub. (HR. Tirmidzi)",
    "Ya Allah, aku berlindung kepada-Mu dari ilmu yang tidak bermanfaat, dari hati yang tidak khusyuk, dari jiwa yang tidak puas, dan dari doa yang tidak didengar. (HR. Muslim)",
    "Ya Allah, perbaikilah segala urusanku dan janganlah Engkau serahkan aku kepada diriku sendiri walau sekejap mata. (HR. Abu Dawud)",
    "Ya Allah, aku berlindung kepada-Mu dari segala penyakit hati dan dari segala macam keburukan. (HR. Bukhari dan Muslim)",
    "Ya Allah, berikanlah aku hidayah dan taufik-Mu dalam setiap langkah hidupku. (HR. Muslim)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang selalu bersyukur dan jauhkanlah aku dari sifat kufur nikmat. (HR. Ahmad)",
    "Ya Allah, jauhkanlah aku dari segala keburukan dan lindungilah aku dari segala macam kejahatan. (HR. Muslim)",
    "Ya Allah, berikanlah aku kekuatan untuk menghadapi setiap ujian yang Engkau berikan. (HR. Muslim)",
    "Ya Allah, berikanlah aku rezeki yang luas, ilmu yang bermanfaat, dan amal yang diterima. (HR. Tirmidzi)",
    "Ya Allah, jadikanlah akhir hayatku sebagai akhir yang terbaik dan janganlah Engkau jadikan dunia sebagai tujuan utama dalam hidupku. (HR. Muslim)",
    "Ya Allah, berikanlah aku keberkahan dalam setiap rezeki yang Engkau berikan dan jadikanlah aku hamba yang selalu bersyukur. (HR. Ahmad)",
    "Ya Allah, ampunilah dosa-dosaku, baik yang kecil maupun yang besar, baik yang tampak maupun yang tersembunyi. (HR. Muslim)",
    "Ya Allah, berikanlah aku kesabaran dalam menghadapi setiap cobaan dan ujian yang Engkau berikan. (HR. Bukhari dan Muslim)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang selalu mengingat-Mu dalam setiap keadaan. (HR. Ahmad)",
    "Ya Allah, berikanlah aku ketenangan hati dan jauhkanlah aku dari segala macam kekhawatiran. (HR. Tirmidzi)",
    "Ya Allah, berikanlah aku kekuatan untuk selalu berbuat kebaikan dan jauhilah aku dari perbuatan yang sia-sia. (HR. Muslim)",
    "Ya Allah, berikanlah aku keberanian untuk mengakui kesalahan dan berikanlah aku kekuatan untuk memperbaikinya. (HR. Bukhari)",
    "Ya Allah, berikanlah aku kemudahan dalam setiap urusan dan jauhkanlah aku dari segala kesulitan. (HR. Tirmidzi)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang sabar dalam menghadapi setiap ujian yang Engkau berikan. (HR. Muslim)",
    "Ya Allah, berikanlah aku rezeki yang halal dan berkah serta jauhkanlah aku dari rezeki yang haram. (HR. Ahmad)",
    "Ya Allah, berikanlah aku keimanan yang kuat dan jauhkanlah aku dari segala macam keraguan. (HR. Bukhari dan Muslim)",
    "Ya Allah, berikanlah aku kekuatan untuk menghadapi setiap tantangan hidup dan berikanlah aku kesabaran dalam setiap ujian. (HR. Muslim)",
    "Ya Allah, jadikanlah aku hamba yang selalu bersyukur atas nikmat-Mu dan jauhkanlah aku dari sifat kufur nikmat. (HR. Ahmad)",
    "Ya Allah, berikanlah aku keberkahan dalam setiap langkah hidupku dan jadikanlah aku hamba yang selalu bertakwa kepada-Mu. (HR. Muslim)",
    "Ya Allah, jauhkanlah aku dari segala macam kesulitan dan berikanlah aku kemudahan dalam setiap urusan. (HR. Tirmidzi)",
    "Ya Allah, berikanlah aku rezeki yang luas dan berkah serta jauhkanlah aku dari rezeki yang haram. (HR. Ahmad)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang selalu bersyukur atas nikmat-Mu dan jauhkanlah aku dari sifat kufur nikmat. (HR. Ahmad)",
    "Ya Allah, berikanlah aku kekuatan untuk selalu berbuat kebaikan dan jauhilah aku dari perbuatan yang sia-sia. (HR. Muslim)",
    "Ya Allah, berikanlah aku keberanian untuk mengakui kesalahan dan berikanlah aku kekuatan untuk memperbaikinya. (HR. Bukhari)",
    "Ya Allah, berikanlah aku ketenangan hati dan jauhkanlah aku dari segala macam kekhawatiran. (HR. Tirmidzi)",
    "Ya Allah, jadikanlah aku termasuk orang-orang yang sabar dalam menghadapi setiap ujian yang Engkau berikan. (HR. Muslim)",
    "Ya Allah, berikanlah aku rezeki yang halal dan berkah serta jauhkanlah aku dari rezeki yang haram. (HR. Ahmad)",
    "Ya Allah, berikanlah aku keimanan yang kuat dan jauhkanlah aku dari segala macam keraguan. (HR. Bukhari dan Muslim)",
    "Ya Allah, berikanlah aku kekuatan untuk menghadapi setiap tantangan hidup dan berikanlah aku kesabaran dalam setiap ujian. (HR. Muslim)",
    "Ya Allah, jadikanlah aku hamba yang selalu bersyukur atas nikmat-Mu dan jauhkanlah aku dari sifat kufur nikmat. (HR. Ahmad)",
    "Ya Allah, berikanlah aku keberkahan dalam setiap langkah hidupku dan jadikanlah aku hamba yang selalu bertakwa kepada-Mu. (HR. Muslim)"
  ];
  